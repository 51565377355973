/* 
    ALL pages have an expanded different photo - same like header below the nav bar
*/

#credits {
    padding: 2.5% 10% 5%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

#credits h2 {
    font-size: 3rem;
}
#credits a:hover {
    cursor: pointer;
    color: purple;
}

#credits h1,
#credits .website,
#credits .images,
#credits .icons {
    width: 100%;
    margin-bottom: 1%;
}

#credits .icons img {
    margin-right: 1%;
}

#credits .icons p {
    margin: 0.5% 0; 
}