/* 
    ALL pages have an expanded different photo - same like header below the nav bar
        Make long text boxes shorter (less width)

    Put intake form where button to digital form goes
    Have a small box for intake form
        User puts in info + clicks next for the next section
        At the end will submit the form

    Intake Form:    
        - Title: New Owner Form
        - Blurb: Intake and medication forms will be filled out at time of checkout
        - Buttons: make them stand out
            - red or blue button
            - on hover change gradient of color
            - add shadow behind it
        - Make it like the Green beagle lodge with a sections + next -> submit
        - Intake Form PDF + digital:
            - Liability waiver: take out pets on
            -Emergency Contact: Take out initials
            - Required info: (Required) in red italics (digital only)
        ** See if I can link form information to TBG Gingr's client form
        ** Possibly just forward clients to the client portal to create an account + do forms there
*/

#forms {
    padding: 2.5% 10% 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

#forms h1{
    width: 100%;
}

#forms #digital,
#forms #pdf {
    display: inherit;
    justify-content: center;
    width: 80%;
    margin-top: 5%;
}

#forms #pdf {
    flex-wrap: wrap;
}

#forms #digital #intake {
    display: inherit;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    width: 70%;
    border: 2px solid black;
    padding: 3%;
}

#forms #digital #intake {
    margin-right: 0.5%;
}

#forms #digital #intake p{
    width: 100%;
    margin: 1% 0;
}

#forms button {
    margin-top: 3%;
}

#forms #pdf p{
    margin: 2% 0;
}

#forms .btns {
    width: 100%;
}
#forms .btns button{
    margin: 2%;
}

